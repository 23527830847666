import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import React from 'react'
import {
  Dispatch,
  SetStateAction,
} from 'react-transition-group/node_modules/@types/react'
import { useMe } from '@/context/meContext'
import styles from './styles.module.scss'
import Link from 'next/link'

interface IProps {
  setMenuVisible: Dispatch<SetStateAction<boolean>>
}

const NavMain = ({ setMenuVisible }: IProps) => {
  const router = useRouter()
  const { t, lang } = useTranslation('common')
  const { me } = useMe()

  const isCurrentPage = (path: string) => router.asPath.includes(path)

  const pages = [
    {
      path: '/companies/jobs',
      title: t('job_offers'),
      lang: ['pl', 'en'],
    },
    {
      path: '/companies/profiles',
      title: t('employer_profiles'),
      lang: ['pl', 'en'],
    },
    {
      path: '/readme',
      title: t('readme'),
      lang: ['pl', 'en'],
    },
    {
      path: '/it-report',
      title: t('report'),
      lang: ['pl', 'en'],
      label: 'New!',
    },
  ]

  return (
    <nav className={`${styles.navMain}`}>
      <ul className='lg:flex pt-4 lg:pt-0'>
        {pages.map((page, index) => {
          if (!page.lang.includes(lang)) {
            return null
          }
          return (
            <li key={index} className='flex items-center'>
              <Link href={page.path} prefetch={false}>
                <a
                  href={page.path}
                  onClick={() => {
                    setMenuVisible(false)
                  }}
                  className={`${
                    isCurrentPage(page.path) ? 'bg-gray-350' : ''
                  } ${styles.navMain__link} flex items-center`}
                >
                  {page.title}
                  {page.label && (
                    <span
                      className={`${styles.navMain__label} bg-purple-home rotate-6`}
                    >
                      <span className='flex items-center text-white uppercase'>
                        <span
                          className={`${styles.cli__newLabel__text} text-white`}
                        >
                          {page.label}
                        </span>
                      </span>
                    </span>
                  )}
                </a>
              </Link>
            </li>
          )
        })}
        <li>
          {!me && (
            <a
              href='/for-employers/pricing'
              type='button'
              className={`${styles.navMain__employersButton}`}
            >
              {t('common:for_employers')}
            </a>
          )}
        </li>
      </ul>
    </nav>
  )
}

export default NavMain
